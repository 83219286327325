<script>
import { Carousel, Slide } from "vue-carousel";
import {
  UserIcon,
  MailIcon,
  KeyIcon,
  ArrowUpIcon,
  HelpCircleIcon,
} from "vue-feather-icons";

import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
import walletApi from "@/apis/walletApi";
import storage from '../storage';
import Features from "@/components/features";
import Testimonial from "@/components/testimonial";
import Navbar2 from "../components/navbar2.vue";
import Withdrawal from "../components/withdrawal.vue";
import Recharge from "../components/recharge.vue";
import Modification from "../components/modification.vue";
import back from "../components/back.vue";
import AccountPasswordUpdateSection from "../views/new-view/components/AccountPasswordUpdateSection.vue";

/**
 * Index-1
 */
export default {
  data() {
    return {
      isAppear: true,
      wallet: undefined,
      walletLoad: 0,
      login: false,
      walletForm: {
        name:"",
        pageNum: 1,
        pageSize: 8
      },
      index: 3,
      tablist: [
        {
          id: 1,
          isActive: 'active',
          title: 'Change Your Password',
          slug: 'account-password-tab',
          bsTarget: '#AccountPassword',
          cTarget: 'AccountPassword'
        },
        // {
        //   id: 2,
        //   title: 'Payment password',
        //   slug: 'payment-password-tab',
        //   bsTarget: '#PaymentPassword',
        //   cTarget: 'PaymentPassword'
        // },
      ]
    }
  },
  components: {
    AccountPasswordUpdateSection,
    Switcher,
    Carousel,
    Slide,
    UserIcon,
    MailIcon,
    KeyIcon,
    ArrowUpIcon,
    Footer,
    HelpCircleIcon,
    Features,
    Testimonial,
    Navbar2,
    Withdrawal,
    Recharge,
    Modification,
	  back
  },
  created() {
    //获取项目列表
    walletApi.balance(res => {
      this.wallet = res.data
    })
    //if login
    let userId = storage.localGet("userId")
    if (userId) {
      this.login = true
    }

    if(this.index === 3 ){
      this.isAppear = false
    }
  },
  methods: {
    loadmore(page) {
      //开始加载
      this.walletLoad = 1
      this.walletForm.pageNum = page
      //获取项目列表
      walletApi.list(this.walletForm, res => {
        this.walletLoad = 0
        Object.assign(this.wallet, res.data)
      })
    },
    //用于手机加载
    loadmore2() {
      this.walletForm.pageNum++
      //开始加载
      this.walletLoad = 1
      let pages = Math.ceil(this.wallet.totalCount / this.walletForm.pageSize)
      //获取项目列表
      if (pages >= this.walletForm.pageNum) {
        walletApi.list(this.walletForm, res => {
          res.data.result.unshift(...this.wallet.result)
          Object.assign(this.wallet, res.data)
          this.walletLoad = 0
          if (pages <= this.walletForm.pageNum) this.walletLoad = -1//结束加载
        })
      } else {
        this.walletLoad = -1;
      }
    },
    indexChange(index) {
      this.index = index;
    }
  },
};
</script>

<template>
  <div>
    <back></back>
    <!-- 项目列表 -->
    <section style="margin-top: 20px;margin-bottom: 80px;">
      <div class="container">
<!--        <el-row :gutter="20">-->
<!--          <el-col :span="14" :xs="24">-->
<!--            <h4>Setting</h4>-->
<!--          </el-col>-->
<!--        </el-row>-->
        <el-row :gutter="20" class="mt-5">
          <el-col>
            <h4 class="text-primary">Change your password</h4>
          </el-col>
        </el-row>
<!--        <el-row :gutter="20" class="mt-4" v-if="isAppear">-->
<!--          <el-col :span="24" :xs="24">-->
<!--            <div class="card p-2 mt-2 border-0 shadow bg-light" >-->
<!--              <Recharge v-if="index === 1"></Recharge>-->
<!--              <Withdrawal v-else-if="index === 2"></Withdrawal>-->
<!--              <Modification v-else></Modification>-->
<!--            </div>-->
<!--          </el-col>-->
<!--        </el-row>-->


        <div class="profile-setting-panel-wrap mt-0">

          <div class="tab-content mt-4" id="myTabContent">
            <div class="tab-pane fade" :class="{active: panel.isActive, show: panel.isActive}" :id="panel.cTarget" role="tabpanel" :aria-labelledby="panel.slug" v-for="panel in tablist" :key="panel.id">
              <div class="profile-setting-panel">
                <!--          <h5 class="mb-4"> {{panel.title}} </h5>-->
                <!--          cTarget: 'AccountPassword'-->
                <template v-if="panel.cTarget === 'AccountPassword'">
                  <AccountPasswordUpdateSection></AccountPasswordUpdateSection>
                </template>

              </div><!-- end profile-setting-panel -->
            </div><!-- end tab-pane -->
          </div>

        </div><!-- end profile-setting-panel-wrap-->
      </div>
    </section>
  </div>
</template>
